
:root {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;
  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;
  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  --muted: 210 40% 96.1%;
  --muted-foreground: 240 3.8% 46.1%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --ring: 222.2 84% 4.9%;
  --radius: 0.5rem;
  --chart-1: 12 76% 61%;
  --chart-2: 173 58% 39%;
  --chart-3: 197 37% 24%;
  --chart-4: 43 74% 66%;
  --chart-5: 27 87% 67%;
  --palette-background-paper: #ffffff;
  --palette-background-default: #cfe3fa;
  --palette-background-card: #ffffff;
}

.dark {
  --background: 240 10% 3.9%;
  --foreground: 210 40% 98%;
  --card: 222.2 84% 4.9%;
  --card-foreground: 210 40% 98%;
  --popover: 222.2 84% 4.9%;
  --popover-foreground: 210 40% 98%;
  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 11.2%;
  --secondary: 217.2 32.6% 17.5%;
  --secondary-foreground: 210 40% 98%;
  --muted: 217.2 32.6% 17.5%;
  --muted-foreground: 215 20.2% 65.1%;
  --accent: 217.2 32.6% 17.5%;
  --accent-foreground: 210 40% 98%;
  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 40% 98%;
  --border: 0 0% 36.1%;
  --input: 217.2 32.6% 17.5%;
  --ring: 212.7 26.8% 83.9%;
  --chart-1: 220 70% 50%;
  --chart-2: 160 60% 45%;
  --chart-3: 30 80% 55%;
  --chart-4: 280 65% 60%;
  --chart-5: 340 75% 55%;
  --palette-background-paper: rgba(29, 35, 44, 1);
  --palette-background-default: rgb(18, 22, 28);
  --palette-background-card: rgba(29, 36, 48, 1);
}
.admin-dashboard .main-content {
  background: #fff;
  border-radius: 16px;
  margin: 18px 0;
  width: calc(81.5% - 26px);
  padding: 20px;
  padding-bottom: 40px;
  box-sizing: border-box;
  overflow: auto;
}
.main-dashboard {
  background-color: rgba(241, 241, 241, 1);
}
html,
body {
  scroll-behavior: smooth !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(248, 248, 248) inset !important;
}

/* remove increment and decrement in number type */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-ms-clear,
input[type="number"]::-ms-reveal {
  display: none;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Poppins";
  opacity: 1;
  /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Poppins";
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: "Poppins";
}

input,
.MuiSelect-select,
.MuiInputBase-input {
  font-family: "Poppins";
}

.react-loading-div {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.grid-cols-2 {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.react-loading {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  white-space: nowrap !important;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd3b;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/DMSans-Medium.woff2") format("woff2"),
    url("../assets/fonts/DMSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/DMSans-Regular.woff2") format("woff2"),
    url("../assets/fonts/DMSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Bold.woff2") format("woff2"), url("../assets/fonts/Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Regular.woff2") format("woff2"),
    url("../assets/fonts/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.outlined-btn {
  color: #00b2ff !important;
  border: 1.3px solid #00b2ff;
  background-color: transparent !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1 !important;
  padding: 10px 15px !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  cursor: pointer;
}

.light-blue-btn {
  color: #fff !important;
  background-color: #00b2ff !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1 !important;
  padding: 10px 15px !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  border: 1.3px solid #00b2ff;
  text-transform: capitalize !important;
  cursor: pointer;
}

.light-red-btn {
  color: #fff !important;
  background-color: #ff5e5e !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1 !important;
  padding: 10px 15px !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  border: none !important;
  text-transform: capitalize !important;
}

.login-container {
  width: 100%;
  float: left;
  height: 100vh;
  display: flex;
  align-items: center;
}

.login-container .login-banner {
  width: 40%;
  background-image: url("../assets/images/auth/login-left-banner.png");
  background-size: 100% 100%;
  background-position: center;
  height: 100%;
  position: relative;
}

.login-form-container > div {
  width: 60%;
}

.login-container .login-banner .login-logo {
  padding: 64px 0 0 72px;
  cursor: pointer;
}

.login-container .login-banner .login-slide-container {
  position: absolute;
  top: 50%;
  left: 72px;
  transform: translateY(-50%);
  max-width: 500px;
}

.login-container .login-banner .login-slide-container .login-slide {
  /* width: 500px !important;
  margin-left: 20px; */
}

.login-container .login-banner .login-slide-container .login-slide h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: 0.001em;
  color: #ffffff;
  margin-bottom: 30px;
}

@media (max-width: 1599px) {
  .login-container .login-banner .login-slide-container .login-slide h3 {
    font-size: 36px;
  }
}

.login-container .login-banner .login-slide-container .login-slide p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.1px;
  color: #ffffff;
  opacity: 0.9;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .login-container .login-banner .login-slide-container .login-slide p {
    font-size: 14px;
    width: 80%;
  }
}

.login-container .login-banner .login-slide-container .line-dots {
  width: 21px;
  height: 7px;
  background: #ffffff;
  opacity: 0.5;
  border-radius: 7px;
}

.login-container .login-banner .login-slide-container .slick-active .line-dots {
  width: 68px;
  height: 7px;
  background: #ffffff;
  border-radius: 7px;
  opacity: 1;
}

.login-container .login-banner .login-slide-container .slick-dots li {
  width: unset;
  height: unset;
}

.login-container .login-banner .login-slide-container .slick-dots {
  text-align: left;
}

.login-container .login-form-container {
  width: 60%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container .login-form-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.login-container .login-form-container::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.login-container .login-form-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #2ea8db52;
}

.login-container .login-form-container .get-started-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0;
}

.login-container .login-form-container .get-started-wrapper .text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0168em;
  color: #747474;
  opacity: 0.9;
  margin-bottom: 5px;
}

.login-container .login-form-container .form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.sub-admin-wrapper .form-wrapper {
  width: 70% !important;
}

.login-container .login-form-container .form-wrapper .welcome-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #050916;
  opacity: 0.6;
  margin-bottom: 10px;
}

.login-container .login-form-container .form-wrapper .login-text {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #231f20;
  padding: 0;
}

.login-container .login-form-container .form-wrapper .cred-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.1px;
  color: #231f20;
  opacity: 0.7;
}

.login-container button {
  padding: 13px 15px !important;
  margin-top: 10px;
}

.error-box {
  background: rgba(255, 241, 241, 0.5);
  border: 1px solid rgba(234, 4, 3, 0.3);
  border-radius: 4.5px;
  padding: 14px 24px;
  margin: 25px 0 0 0;
}

.error-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #01052d;
  opacity: 0.8;
}

.login-container .login-form-container .form-wrapper .form .login-container .login-form-container .form-wrapper .form {
  margin-top: 35px;
  overflow: auto;
  width: 100%;
  margin-bottom: 35px;
}

.login-container .login-form-container .form-wrapper .form::-webkit-scrollbar {
  display: none;
}

.login-container .login-form-container .form-wrapper .form::-webkit-scrollbar-thumb {
  background: #2e635e;
  border-radius: 10px;
}

.login-container .login-form-container .form-wrapper .form label {
  font-weight: 500;
  color: #231f20;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.form-wrapper .form .form-group {
  margin-bottom: 10px;
}

.form-wrapper .form .action-btn {
  display: flex;
  gap: 20px;
}

.login-container .login-form-container .form-wrapper .form .remember-forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-container .login-form-container .form-wrapper .form .remember-forget label span {
  margin-bottom: 0;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  opacity: 0.7;
}

.login-container .login-form-container .form-wrapper .form .remember-forget .forget-password {
  font-style: normal;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 500;
  color: #2ea8db;
  cursor: pointer;
}

.login-container .login-form-container .form-wrapper .form .remember-forget .forget-password:hover {
  text-decoration: underline;
}

.sign-up-google {
  color: #231f20 !important;
  border: 1px solid rgba(21, 34, 73, 0.2);
}

.sign-up-google:hover {
  border: 1px solid rgba(21, 34, 73, 0.2) !important;
}

.register-with {
  margin-top: 28px;
  margin-bottom: 28px;
  text-align: center;
  position: relative;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.register-with::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  left: 0;
  top: 50%;
  z-index: -1;
}

.register-with p {
  display: inline-block;
  background-color: #fff;
  padding: 0 12px;
}

.sign-up-google {
  width: 100%;
  padding: 18px !important;
}

.lisence-argrement > div {
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 35px;
}

.lisence-argrement p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}

.select-options:nth-child(even) {
  background: rgba(46, 168, 219, 0.05);
}

.select-options {
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  padding-left: 24px !important;
}

.select-options:hover {
  background-color: rgb(208 244 255) !important;
}

.login-container .reset-btn-wrapper a {
  font-style: normal;
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  color: #2ea8db;
  cursor: pointer;
}

.login-container .reset-btn-wrapper a:hover {
  text-decoration: underline;
}

.login-container .reset-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 22px;
}

.login-container .login-form-container .form-wrapper .form .form-group > div input {
  padding: 10px 14px !important;
}

.login-container .login-form-container .form-wrapper .form .form-group > div .MuiSelect-select {
  padding: 10px 14px !important;
}

.login-container .login-form-container .form-wrapper .form {
  margin-top: 20px;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  /* signup page */
  .login-container .login-banner .login-logo {
    padding: 25px 0 0 36px;
    width: 100px;
  }

  .login-container .login-form-container .form-wrapper .login-text {
    font-size: 24px;
  }

  .login-container .login-form-container .form-wrapper .cred-text,
  .login-container .login-form-container .get-started-wrapper .text {
    font-size: 14px !important;
  }

  .login-container .login-banner .login-slide-container {
    left: 36px;
    max-width: 79%;
  }

  .login-container .login-form-container .form-wrapper .welcome-text {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 13px;
  }

  .login-container .login-form-container .form-wrapper .form .remember-forget label span,
  .login-container .login-form-container .form-wrapper .form .remember-forget .forget-password,
  .login-container .login-form-container .form-wrapper .form label {
    font-size: 14px;
  }

  .lisence-argrement > div {
    margin-bottom: 20px;
  }
}

.submit-btn.disabled {
  background-color: #e0e0e0 !important;
  cursor: auto;
}

.form p a {
  color: #2ea8db;
}

.form-control-select em {
  color: #00000063;
}

.error-input {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #d70000;
  padding-top: 7px;
}

.login-form-container .complete-profile-text-wrapper {
  min-width: 705px;
  max-width: 705px;
  margin: auto;
}

.login-form-container .complete-profile-text-wrapper h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  text-transform: uppercase;
  color: #231f20;
}

.login-form-container .edit-profile-text-wrapper {
  min-width: 705px;
  max-width: 705px;
  margin: auto;
  padding: 80px 0;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1599px) {
  .login-form-container .edit-profile-text-wrapper {
    padding: 40px 0;
  }

  .login-form-container .complete-profile-text-wrapper {
    min-width: 638px;
    max-width: 638px;
  }

  .login-form-container .edit-profile-text-wrapper {
    min-width: 638px;
    max-width: 638px;
  }

  .login-container.edit-profile .login-form-container .form-wrapper {
    /* height: calc(100vh - 144px); */
  }
}

@media (max-width: 765px) {
  .login-form-container > div {
    width: 95%;
  }
}

.login-form-container .edit-profile-text-wrapper h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #231f20;
  border-left: 5px solid #2ea8db;
  padding-left: 10px;
}

.login-form-container .form-wrapper .verify-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #231f20;
  padding: 0 0 10px 0;
}

.login-form-container .edit-profile-text-wrapper p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0168em;
  color: #747474;
  opacity: 0.9;
}

.login-form-container .edit-profile-text-wrapper p span {
  color: red;
}

/* //popup for register */
/* / modal dialog / */
.dialog-wrapper .MuiDialog-container > div {
  border-radius: 10px;
}

.success-Modal {
  padding: 21px 84px 30px 84px;
}

.success-Modal .success-loader-logo {
  margin: auto;
  width: 160px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-Modal .success-title h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #231f20;
}

.success-Modal .success-description {
  width: 354px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin-top: 12px;
  margin-bottom: 17px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  color: #231f20;
}

.success-Modal .success-btn-wrapper button {
  /* / height: 40px; / */
  padding: 10px 22px !important;
  width: 64px;
  background: #0073ff;
  border-radius: 3.92157px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14.1176px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.success-Modal .checkmark {
  /* width: 56px;
  height: 56px; */
  width: 70%;
  height: 70%;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #0073ff;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #0073ff;
  fill: #0073ff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.MuiInputLabel-root.input-label,
.input_label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 1.3 !important;
  color: #231f20;
  opacity: 0.9;
  margin-bottom: 4px !important;
  text-transform: capitalize !important;
}

.input_label {
  display: flex;
  align-items: center;
  gap: 4px;
}

.MuiInputLabel-root.input-label span.mandatory {
  color: red;
}

.action-btn {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.permission {
  display: flex;
  gap: 10px;
}

.permission .approved,
.permission .decline {
  width: 25px;
  height: 25px;
  border-radius: 7px;
}

.permission .approved svg,
.permission .decline svg {
  width: 15px;
  height: 15px;
}

.permission .approved {
  background-color: #12c473;
}

.permission .decline {
  background-color: #ef0b0b;
}

.table-loading-wrapper {
  height: 100px;
  background-color: white !important;
}

.sync-loader-wrapper {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}

/* //verify email */

.verify-email-Modal {
  padding: 21px 84px 30px 84px;
}

.verify-email-Modal .verify-email-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.verify-email-Modal .verify-email-title h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  color: #231f20;
}

.verify-email-Modal .verify-email-title p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  opacity: 0.5;
}

.verify-email-Modal .verify-email-content {
  display: flex;
  flex-direction: column;
  gap: 45px;
  align-items: center;
}

.verify-email-Modal .verify-email-action {
  gap: 20px;
}

.verify-email-Modal .verify-email-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 23px auto 17px;
  text-align: center;
  color: #231f20;
}

.verify-email-Modal .verify-email-action button {
  padding: 10px 22px !important;
  background: #2ea8db;
  border-radius: 3.92157px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14.1176px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  text-transform: none;
}

.verify-email-Modal .verify-email-action button.outlined {
  background: transparent;
  color: #2ea8db;
}

.form-wrapper .form form p span {
  color: #2ea8db;
  text-decoration: underline;
  cursor: pointer;
}

.terms-condition-Modal {
  padding: 21px 50px;
}

.terms-condition-Modal .terms-condition-title {
  display: flex;
  justify-content: flex-end;
}

.terms-condition-Modal .terms-condition-title svg {
  cursor: pointer;
}

.terms-condition-Modal .terms-condition-content {
  max-height: 400px;
}

.terms-condition-Modal .terms-condition-content h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 32px;
  line-height: 48px;
  color: #000000;
}

.terms-condition-Modal .terms-condition-content p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  margin-bottom: 40px;
  color: #000000;
  opacity: 0.7;
}

.terms-condition-Modal .terms-condition-action button {
  padding: 10px 22px !important;
  background: #2ea8db;
  border-radius: 3.92157px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14.1176px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  text-transform: none;
}

.terms-condition-Modal .terms-condition-content::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  background-color: #f5f5f5;
}

.terms-condition-Modal .terms-condition-content::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.terms-condition-Modal .terms-condition-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #00b2ff;
}

.donut-tooltip {
  padding: 5px 10px;
  background-color: #ffffff;
  box-shadow: 0px 21.8972px 65.6916px rgba(0, 0, 0, 0.05);
  color: #000;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* gap: 11px; */
}

.dashboard-nav .right-nav img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  object-position: 50% 0%;
}

.donut-tooltip p {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 21.8972px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #000000;
}

.donut-tooltip h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
}

.admin-dashboard .main-content .title button {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 8px 15px;
  font-size: 14px;
  background: #00b2ff;
  border-radius: 5px;
  border: none;
  color: #fdfdfd;
}

.subadmin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.filter-btn {
  padding: 8px 15px;
  width: 85px;
  border: 0;
  display: block;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #242731;
  opacity: 0.7;
  background: #2ea8db1a;
  border-radius: 4px;
  position: relative;
  text-align: right;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.filter-btn::before {
  content: "";
  width: 14px;
  height: 14px;
  background: url("../assets/images/pages/clientManagement/filter-icon.png") no-repeat 100% center;
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
}

.export-btn {
  padding: 8px 15px;
  border: 0;
  display: inline-block;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #242731;
  opacity: 0.7;
  background: #2ea8db1a;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.export-btn:hover {
  transform: scale(1.1);
}

.filter-btn:hover {
  transform: scale(1.1);
}

.form-group-full {
  width: 90%;
}

input,
.MuiSelect-select,
.MuiInputBase-input {
  font-size: 13px !important;
  font-family: "Poppins" !important;
}

.subadmin-table .table-wrapper {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 25px;
  position: relative;
}

.admin-dashboard .main-content .title {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-btn-card {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: 30px;
}

.admin-dashboard .main-content .title h1 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 20px;
  color: #231f20;
  display: flex;
  align-items: center;
  gap: 15px;
}

.menu-box {
  display: none;
}

.title-btn button {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .menu-box {
    display: block;
    margin: 0 15px;
  }

  .login-container .login-form-container {
    width: 100%;
    background-color: #ccc;

    & > div {
      background-color: #fff;
      padding: 20px;
      border-radius: 16px;
    }
  }

  .dashboard-leftsidebar {
    position: absolute;
    height: 100vh;
    top: 0;
    overflow-y: scroll !important;
    background-color: #ffffff;
    width: 350px !important;
    z-index: 9999;
    left: -350px;
    opacity: 0;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
  }

  .dashboard-leftsidebar.show {
    left: 0;
    opacity: 1;
  }

  .admin-dashboard .main-content.adm-dashboard,
  .admin-dashboard .main-content {
    width: 100% !important;
    padding: 10px 20px;
  }

  #overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
  }
}

@media only screen and (max-width: 600px) {
  .admin-dashboard .main-content.adm-dashboard,
  .admin-dashboard .main-content {
    width: 100% !important;
    padding: 10px;
  }

  .dashboard-nav .right-nav img {
    display: none;
  }
}

.title-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* test*/

.sub-admin-wrapper .tab-content {
  padding: 20px;
}

.form_title {
  margin-bottom: 15px;
}

.form_title h1 {
  text-transform: uppercase !important;
  font-size: 18px !important;
  line-height: 1.2 !important;
  font-weight: 500;
}

.form-group .MuiInputBase-sizeSmall {
  width: 100% !important;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.no-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  /* Firefox */
}

.table-green-btn {
  padding: 5px 10px;
  background-color: #12c473;
  color: #fff;
  border-radius: 4px;
  border: none;
}

.table-grey-btn {
  padding: 5px 10px;
  background-color: #808080;
  color: #fff;
  border-radius: 4px;
  border: none;
}

.custom_input {
  margin-bottom: 10px;
}

body button:disabled {
  border: none !important;
}

.subadmin-btn {
  display: flex;
  align-items: center;
  position: relative !important;
}

.filter-container {
  width: 250px;
  background-color: #fdfdfd;
  border-radius: 10px;
  padding: 18px;
  box-shadow: 0px 6px 34px 0.2px rgba(215, 219, 222, 0.567);
  box-sizing: border-box;
  position: absolute;
  top: 45px;
  right: 50%;
  z-index: 999 !important;
}

.filter-container .close-icon {
  text-align: right;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.filter-container .close-icon h1 {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
}

.filter-wrapper {
  display: flex;
  flex-direction: column;
}

.filter-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
}

.datepicker-input > div {
  width: 100%;
}

.filter-btn-wrapper button {
  width: 50% !important;
  margin: 0 !important;
}

.ques-opt img {
  width: 70px;
  display: flex;
}

.clear_all_btn {
  width: 120px;
  color: #ff0000;
  background: #fff2f2;
}

.clear_all_btn::before {
  background: url("../assets/new-icons/clear_all_icon.svg") no-repeat 100% center;
}
.style_webCam__o80t4 {
  visibility: hidden;
  max-width: 100%;
}
.greyText {
  word-break: break-all !important;
}
.more-dropdown {
  border: 1px solid #ccc;
}
