.landing_main_wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing_container {
  width: 50%;
  height: 50vh;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.title_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-size: 30px;
  font-family: "Poppins";
}

.links_wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.links_card {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.links_card:hover {
  transform: scale(1.1);
}

a {
  text-decoration: none;
}
