.card {
    background-color: white;
    /* border-radius: 8px; */
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
    width: 100% ;
    height: 100%;
  }
  
  .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .card-title {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .legend {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: 1rem;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .legend-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  
  .legend-label {
    font-size: 0.7rem;
    color: #666;
  }
  
  .select-container {
    position: relative;
  }
  
  .select-trigger {
    display: flex;
    align-items: center;
    height: 2rem;
    padding: 0 0.5rem;
    font-size: 0.75rem;
    border: 1px solid #7c7c7c;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
  }
  
  .select-icon {
    margin-right: 0.5rem;
  }
  
  .card-content {
    /* padding: 1rem; */
  }
  
  .chart-container {
    height: 300px;
  }
  
  