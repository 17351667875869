.dashboard-totalview {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .dashboard-total-listitem {
    width: 25%;
    background-color: var(--palette-background-card);
    border: 1.2px solid hsl(var(--border));
    border-radius: 12px;
    padding: 20px;
    padding-bottom: 15px;
    & .card-title p {
      font-size: 0.75rem;
      opacity: 0.8;
    }
    & .info {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
  
      & .val-txt {
        width: 50%;
      }
      & .val-txt h3 {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  
    & .report {
      display: inline-flex;
      gap: 8px;
      font-size: 0.7rem;
      align-items: center;
      margin-top: 10px;
      color: rgba(144, 149, 160, 1);
  
      & .report-current-status {
        display: inline-flex;
        gap: 5px;
        align-items: center;
        color: rgba(55, 181, 102, 1);
      }
    }
  }
  
  @media (max-width: 1199px) {
    .dashboard-totalview {
      flex-wrap: wrap;
      gap: 20px;
    }
    .dashboard-totalview .total-listitem {
      width: calc(50% - 10px);
    }
  }


  .dashboard-totalview {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .dashboard-total-listitem {
    width: 25%;
    background-color: var(--palette-background-card);
    border: 1.2px solid hsl(var(--border));
    border-radius: 12px;
    padding: 20px;
    padding-bottom: 15px;
    & .card-title{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    & .card-title p {
      font-size: 0.75rem;
      opacity: 0.8;
    }
    & .info {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
  
      & .val-txt {
        width: 50%;
      }
      & .val-txt h3 {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  
    & .report {
      display: inline-flex;
      gap: 8px;
      font-size: 0.7rem;
      align-items: center;
      margin-top: 10px;
      color: rgba(144, 149, 160, 1);
  
      & .report-current-status {
        display: inline-flex;
        gap: 5px;
        align-items: center;
        color: rgba(55, 181, 102, 1);
      }
    }
  }
  
  @media (max-width: 1199px) {
    .dashboard-totalview {
      flex-wrap: wrap;
      gap: 20px;
    }
    .dashboard-totalview .total-listitem {
      width: calc(50% - 10px);
    }
  }
  