.dialog-wrapper .MuiDialog-container > div {
  background: #ffffff;
  border-radius: 12px;
}

.delete-Modal {
  padding: 20px;
  box-sizing: border-box;
}

.delete-Modal .title h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #1d1d1d;
  text-align: center;
}

.delete-Modal .content-body {
  margin-top: 10px;
}
.delete-Modal .content-body p {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: #000000;
  text-align: center;
  opacity: 0.7;
}

.delete-Modal .action-btn-wrapper {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.delete-Modal .action-btn-wrapper button {
  width: 50%;
}
