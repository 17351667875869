.dashboard-leftsidebar li a {
  display: flex;
  align-items: center;
  padding: 12px 14px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 13px !important;
  line-height: 24px;
  text-decoration: none;
  color: #4f547b;
  margin: 5px 0;
}

.dashboard-leftsidebar li.active-main > a {
  background: linear-gradient(90deg, #13b8ff 0%, #048bc3 113.68%);
  border-radius: 8px;
  color: #fff;
}
.sub-list .sub-list-items.active a {
  font-weight: 500 !important;
  font-family: "Poppins";
  color: #000;
  border-radius: 7px;
}

.dashboard-leftsidebar li a span {
  margin-right: 10px;
  display: inline-flex;
  width: 26px;
}

.dashboard-leftsidebar li.active-main a svg path {
  stroke: #fff;
}

.dashboard-leftsidebar {
  width: 19%;
  background-color: #fff;
  border-right: 1.4px solid rgba(223, 223, 223, 1);
}
.dashboard-leftsidebar ul {
  overflow: auto;
  max-height: 85vh;
  margin-top: 10px;
  padding: 0 15px;
}

.dashboard-leftsidebar ul::-webkit-scrollbar {
  width: 5px;
}
.dashboard-leftsidebar ul::-webkit-scrollbar-track {
  background: transparent;
}

.dashboard-leftsidebar ul::-webkit-scrollbar-thumb {
  background: #2db6f5;
  border-radius: 3px;
}

.admin-dashboard .main-dashboard {
  width: 100%;
  display: flex;
  gap: 10px;
  height: calc(100vh - 91px);
}

.permission_loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 10px;
  text-decoration: none;
}
.dashboard-leftsidebar .sub-list {
  padding: 0;
}

.dashboard-leftsidebar li.active-main .sub-list {
  padding-top: 10px;
}

.dashboard-leftsidebar .sub-item-btn {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.dashboard-leftsidebar .sub-item-btn .rotate-item {
  rotate: 90deg;
}
.dashboard-leftsidebar .sub-list-items:last-child a {
  padding-bottom: 0px;
}
.dashboard-leftsidebar .sub-list-items a {
  padding: 0 15px;
  padding-bottom: 8px;
}
.submenu {
  padding: 0px 0px 0px 20px !important;
  list-style: none;
  /* margin: 0; */
  width: 236px;
  margin-top: -14px;
  box-sizing: border-box;
  height: 100px;
}

.submenu ul li {
  padding: 10px 0 !important;
  margin-bottom: 5px;
}

.submenu li a {
  color: #4f547b !important;
  text-decoration: none !important;
  transition: color 0.3s !important;
  padding: 10px 10px !important;
}

/* responsive for 1200px to 1599px  */

@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .dashboard-leftsidebar ul li a span {
    width: 22px;
  }

  .admin-dashboard .main-dashboard {
    height: calc(100vh - 69px);
  }
}

@media (max-width: 1400px) {
  .dashboard-leftsidebar ul li a {
    font-size: 14px;
    padding: 8px 10px;
  }
}
