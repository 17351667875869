.subadmin-table {
  width: 100%;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px -4px 19px rgba(14, 118, 140, 0.03);
  border-radius: 10px;
}

.subadmin-table table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.subadmin-table .table-wrapper::-webkit-scrollbar-track {
  background: #e9e9e9;
}

.subadmin-table .table-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 12px;
  background: #2ea8db26;
}

.subadmin-table .table-wrapper::-webkit-scrollbar-thumb {
  background: #a8e4ff;
  border-radius: 30px;
}

.subadmin-table thead {
  background: #2ea8db26;
}

.subadmin-table thead th {
  text-align: center;
  padding: 10px 20px 10px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #242731;
  white-space: nowrap;
}

.subadmin-table tbody tr:nth-child(even) {
  background: rgba(46, 168, 219, 0.05);
}

.subadmin-table tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.subadmin-table tbody td:first-child,
.subadmin-table thead th:first-child {
  padding-left: 20px;
  padding-right: 15px;
}

.subadmin-table tbody td:last-child,
.subadmin-table thead th:last-child {
  padding: 0 34px;
}
.subadmin-table thead th:last-child .subadmin-title {
  justify-content: center;
}

.subadmin-table tbody td:last-child label,
.subadmin-table thead th:last-child {
  margin-right: 0;
}
.subadmin-table td {
  padding: 8px;
  padding-left: 0;
  border: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #231f20;
  white-space: nowrap;
}

.subadmin-btn button {
  margin-left: 15px;
}

.subadmin-title {
  display: flex;
  align-items: center;
}

.subadmin-title p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.subadmin-title .title-arrow {
  display: flex;
  flex-direction: column;
  padding-left: 9px;
  gap: 5px;
}

.subadmin-title .title-arrow button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
  width: 11px;
  height: auto;
  display: flex;
}

.subadmin-table .up-arrow.grayed-out svg path {
  fill: #454545;
}
.subadmin-table .down-arrow.grayed-out svg path {
  fill: #454545;
}
.subadmin-table th {
  border: unset;
}

@media (max-width: 1440px) {
  .subadmin-title p {
    font-size: 12px;
  }
}
.subadmin-table table .no-list-table {
  height: 100px;
}
.subadmin-table table .no-list-table td {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}
