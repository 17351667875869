.event-time-head {
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  font-size: 0.65rem;

  & .event-period {
    color: blue;
  }
  & .event-status span {
    color: rgb(255, 59, 59);
    font-size: 0.5rem;
    background-color: rgb(251, 202, 202);
    padding: 2px 6px;
    border-radius: 10px;
  }
}

.event-details-list {
  & p {
    font-size: 0.6rem;
    font-weight: bold;
    opacity: 0.8;
    & span {
      font-weight: 400;
    }
  }
}
