.multiple-chart_pieChart {
    text-align: center;
    margin-top: 3rem;
  }
  
  .multiple-chart-wrapper {
    position: relative;
    width: 120px;
    height: 120px;
    margin: auto;
  }
  
  .chart-inner-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .chart-label{
    font-size: 13px;
    margin-top: 12px;
    font-weight: 500;
  }