@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.admin-dashboard .main-content.adm-dashboard .dashboard-title h1 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: hsl(var(--primary));
  margin-bottom: 0px;
}

.admin-dashboard .main-content.adm-dashboard .dashboard-title {
  margin-bottom: 35px;
  flex-direction: column;
}

.admin-dashboard .main-content.adm-dashboard .dashboard-title p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(36, 39, 49, 0.464);
  line-height: 18px;
}

@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .admin-dashboard .main-content.adm-dashboard .dashboard-title {
    margin-bottom: 35px;
  }
}
.main-content.adm-dashboard .graph_tooltip {
  background: #ffffff;
  box-shadow: 0px 2.53759px 12.688px rgba(4, 28, 64, 0.11);
  padding: 14px 38px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-content.adm-dashboard .graph_tooltip h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #010207;
}
.main-content.adm-dashboard .graph_tooltip p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #010207;
  opacity: 0.8;
}

.main-content.adm-dashboard .upcoming-assignment {
  margin: 40px 0 0 0;
}

.main-content.adm-dashboard .upcoming-assignment .upcoming-assignment-cards {
  max-height: 307px;
  overflow-y: auto;
}
.main-content.adm-dashboard .upcoming-assignment .upcoming-assignment-cards::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
.main-content.adm-dashboard .upcoming-assignment .upcoming-assignment-cards::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.main-content.adm-dashboard .upcoming-assignment .upcoming-assignment-cards::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #00b2ff;
}
.main-content.adm-dashboard .select-and-more-wrapper .calender {
  position: relative;
}
.main-content.adm-dashboard .select-and-more-wrapper .calender .more-dropdown {
  left: -117px;
}
.main-content.adm-dashboard .react-calendar__navigation button {
  min-width: 170px;
}
.main-content.adm-dashboard .react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
  opacity: 0.5;
}

.main-content.adm-dashboard .list-card .card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px 10px 20px;
  border-bottom: 1px solid #ededed;
}

.main-content.adm-dashboard .list-card .card-title h2 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #260944;
}

.main-content.adm-dashboard .list-card .card-title a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  text-underline-offset: 5px;
  color: #2ea8db;
}
.main-content.adm-dashboard .list-card .card-lists .recharts-wrapper {
  margin: auto;
}

.recharts-wrapper ul li {
  font-size: 14px;
  margin-right: 12px !important;
}
.card-lists.pie-results {
  height: 380px;
  display: flex;
  align-items: center;
}
.main-content.adm-dashboard .list-card .card-total {
  background: rgba(22, 101, 216, 0.06);
  border-radius: 6.36191px;
  max-width: 240px;
  margin: 20px auto;
}
.main-content.adm-dashboard .list-card .card-total p {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 19.0857px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #242731;
  opacity: 0.8;
  padding: 12px 0;
  text-align: center;
}

.main-content.adm-dashboard .list-card .card-list {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  align-items: flex-end;
}

.main-content.adm-dashboard .list-card .card-list .profile-name {
  display: flex;
}

.main-content.adm-dashboard .list-card .card-list .profile-name img {
  margin-right: 10px;
}

.main-content.adm-dashboard .list-card .card-list:not(:first-child) {
  border-top: 1px solid #ededed;
}

.main-content.adm-dashboard .list-card .card-list .profile-name .name-review {
  display: grid;
  align-items: flex-end;
}

.main-content.adm-dashboard .list-card .card-list .profile-name .name-review .name {
  margin-bottom: 4px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
}

.main-content.adm-dashboard .list-card .card-list .profile-name .name-review .review,
.main-content.adm-dashboard .list-card .card-list .students,
.main-content.adm-dashboard .list-card .card-list .courses {
  font-family: "Poppins";
  font-weight: 400;
  line-height: 13px;
}

.main-content.adm-dashboard .list-card .card-list .profile-name .name-review .review {
  text-align: right;
  font-size: 13px;
}

.main-content.adm-dashboard .list-card .card-list .students {
  font-size: 12px;
}

.main-content.adm-dashboard .list-card .card-list .courses {
  font-size: 13px;
}

/* recent-courses card */
.main-content.adm-dashboard .recent-courses .card-list .course-detail {
  margin-left: 15px;
}

.main-content.adm-dashboard .recent-courses .card-list .course-detail .details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.main-content.adm-dashboard .recent-courses .card-list .course-detail .details .profile {
  display: flex;
}

.main-content.adm-dashboard .recent-courses .card-list .course-detail .details .profile img {
  margin-right: 8px;
}

.main-content.adm-dashboard .recent-courses .card-title h2 {
  font-size: 16px;
  line-height: 25px;
}

.main-content.adm-dashboard .recent-courses .card-list .course-detail .courser-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

.main-content.adm-dashboard .recent-courses .card-list .course-detail .details {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.main-content.adm-dashboard .statictics-wrapper {
  display: flex;
  margin-top: 36px;
  justify-content: space-between;
}

.main-content.adm-dashboard .posts-wrrapper {
  display: flex;
  margin-top: 65px;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.main-content.adm-dashboard .select-and-more-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.main-content.adm-dashboard .select-and-more-wrapper .more-icon-wrapper svg {
  padding: 0px;
  cursor: pointer;
}
.main-content.adm-dashboard .select-and-more-wrapper .more-dropdown {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2.42105px 12.1053px rgba(57, 35, 196, 0.15);
  border-radius: 5px;
  top: 18px;
  z-index: 1;
}
.main-content.adm-dashboard .select-and-more-wrapper .more-dropdown li {
  padding: 8px 20px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
}
.main-content.adm-dashboard .select-and-more-wrapper .more-dropdown li:hover {
  background: #00b3ff21;
}
.main-content.adm-dashboard .select-and-more-wrapper .more-dropdown li:hover span {
  color: #00b2ff;
}
.main-content.adm-dashboard .select-and-more-wrapper .more-dropdown li:hover svg path {
  fill: #00b2ff;
}
.main-content.adm-dashboard .select-and-more-wrapper .more-dropdown svg {
  padding: unset;
}
.main-content.adm-dashboard .select-type-dropdown {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 16px 20px;
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 8px;
  cursor: pointer;
}
.main-content.adm-dashboard .select-type-dropdown span {
  color: #4f547b;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  user-select: none;
}
.main-content.adm-dashboard .select-type-dropdown svg {
  transition: all 0.5s ease 0s;
}
.main-content.adm-dashboard .select-dropdown-ul {
  position: absolute;
  top: 52px;
  background-color: white;
  box-shadow: 0px 4px 16px 0.5px rgba(226, 223, 237, 0.25);
  border-radius: 8px;
  z-index: 1;
  border: 1px solid #ededed;
}
.main-content.adm-dashboard .select-dropdown-ul li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  cursor: pointer;
  color: #4f547b;
  opacity: 0.5;
  padding: 10px;
  border: 1px solid #ededed;
}
.main-content.adm-dashboard .select-dropdown-ul li:hover,
.main-content.adm-dashboard .select-dropdown-ul li.active {
  opacity: 1;
}
.main-content.adm-dashboard .list-card .card-lists.notify-cards {
  padding: 0 25px;
}
.main-content.adm-dashboard .db-more-icon {
  padding: 10px;
  cursor: pointer;
}
.main-content.adm-dashboard .more-icon-wrapper {
  position: relative;
}

.main-content.adm-dashboard .list-card.earning-statistics .more-dropdown,
.main-content.adm-dashboard .select-and-more-wrapper .more-dropdown {
  right: 20px;
}
.main-content.adm-dashboard .notification-loading {
  display: flex;
  justify-content: center;
  margin: 35px 0 20px;
}

.main-content.adm-dashboard .list-card .card-lists.notify-cards {
  max-height: 307px;
  overflow-y: auto;
  padding-bottom: 0;
}
.main-content.adm-dashboard .list-card .card-lists.notify-cards::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
.main-content.adm-dashboard .list-card .card-lists.notify-cards::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.main-content.adm-dashboard .list-card .card-lists.notify-cards::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #00b2ff;
}
.admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .charts {
  width: calc(60% - 5px);
}
.main-content.adm-dashboard .list-card.earning-statistics .loading-wrapper {
  margin: 10px 30px 10px;
  padding-bottom: 15px;
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .calendar {
  width: calc(40% - 5px);
}
.admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .charts .earning-statistics {
  width: 100%;
}
.admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .charts .client-statistics {
  width: calc(50% - 10px);
}
.admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .charts > div {
  margin-bottom: 10px;
}
.admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.main-content.adm-dashboard .custom-type-select {
  position: relative;
}
.main-content.adm-dashboard .custom-type-select .select-dropdown-ul {
  width: 100%;
  user-select: none;
}

.admin-dashboard .main-dashboard .main-content.adm-dashboard .list-card.client-overview {
  width: 100%;
}
.main-content.adm-dashboard .list-card.client-overview .card-title {
  border-bottom: unset;
}
.main-content.adm-dashboard .list-card.client-overview .table {
  overflow-x: auto;
  position: relative;
  width: 100%;
  white-space: nowrap;
}

.main-content.adm-dashboard .list-card.client-overview .table::-webkit-scrollbar-track {
  background: #e9e9e9;
}

.main-content.adm-dashboard .list-card.client-overview .table::-webkit-scrollbar {
  width: 5px;
  height: 12px;
  background: #2ea8db26;
}

.main-content.adm-dashboard .list-card.client-overview .table::-webkit-scrollbar-thumb {
  background: #a8e4ff;
  border-radius: 30px;
}

.main-content.adm-dashboard .list-card.client-overview .table table thead {
  background: #f7faff;
}
.main-content.adm-dashboard .list-card.client-overview .table table td {
  border: unset;
}
.main-content.adm-dashboard .list-card.client-overview .table table thead td {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #260944;
  padding: 10px;
}
.main-content.adm-dashboard .list-card.client-overview .table table tbody td {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #5b5b98;
  padding: 10px;
  padding-bottom: 0;
}
.admin-dashboard .main-content.adm-dashboard .client-overview table .client-prof .img {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  overflow: hidden;
  background: #a8e4ff;
  min-width: 40px;
}
.admin-dashboard .main-content.adm-dashboard .client-overview table .client-prof .avatar {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-color: #a8e4ff;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.admin-dashboard .main-content.adm-dashboard .client-overview table .client-prof .avatar p {
  color: #000;
  font-weight: 900;
}
.admin-dashboard .main-content.adm-dashboard .client-overview table .client-prof .img img {
  width: 100%;
}
.main-content.adm-dashboard .list-card.client-overview .table table tbody td .client-prof {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
}
.main-content.adm-dashboard .list-card.client-overview .table table tbody td .client-prof .info {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
}
.main-content.adm-dashboard .list-card.client-overview .table table tbody td .client-prof .info h6 {
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #260944;
}
.main-content.adm-dashboard .list-card.client-overview .table table tbody td .status {
  text-align: left;
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  padding: 0;
  font-weight: 500;
  line-height: 14px;
  border-radius: 4px;
}
.main-content.adm-dashboard .list-card.client-overview .table table tbody td .status.active {
  color: #00b69b;
  background: rgba(0, 182, 155, 0.1);
}
.main-content.adm-dashboard .list-card.client-overview .table table tbody td .status.inactive {
  color: #ee368c;
  background: rgba(238, 54, 140, 0.1);
}

@media (min-width: 1600px) {
  .admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .charts {
    width: calc(70% - 5px);
  }
  .admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .calendar {
    width: calc(30% - 5px);
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .charts {
    width: calc(55% - 5px);
  }
  .admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .calendar {
    width: calc(45% - 5px);
  }
  .admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .charts .client-statistics {
    width: 100%;
  }
}
@media (min-width: 1440px) and (max-width: 1599px) {
  .admin-dashboard
    .main-dashboard
    .main-content.adm-dashboard
    .charts-wrapper
    .charts
    .client-statistics
    .card-title
    h2 {
    width: 35%;
  }
  .admin-dashboard
    .main-dashboard
    .main-content.adm-dashboard
    .charts-wrapper
    .charts
    .client-statistics
    .select-and-more-wrapper {
    gap: 10px;
    width: calc(65% - 0px);
    justify-content: flex-end;
  }
  .admin-dashboard
    .main-dashboard
    .main-content.adm-dashboard
    .charts-wrapper
    .charts
    .client-statistics
    .select-type-dropdown {
    gap: 10px;
    padding: 8px 12px;
  }
  .admin-dashboard
    .main-dashboard
    .main-content.adm-dashboard
    .charts-wrapper
    .charts
    .client-statistics
    .select-dropdown-ul
    li {
    padding: 10px 17px;
  }
}

@media (min-width: 1440px) {
  .main-content.adm-dashboard .recharts-text {
    transform: rotate(0deg);
  }
}
.main-content.adm-dashboard .no-client-statistics {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
}

.dashboard-table-pagination {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}
.dashboard-table-pagination .limit {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.dashboard-table-pagination .limit p {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #5b5b98;
}
.dashboard-table-pagination .limit .limit-dropdown {
  margin-bottom: -5px;
}
.dashboard-table-pagination .limit .limit-dropdown #demo-simple-select {
  padding: 0;
  padding-right: 30px;
  position: relative;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: hsl(var(--primary));
}
.dashboard-table-pagination .limit .limit-dropdown fieldset {
  border-radius: 0;
  border: none;
}
.dashboard-table-pagination .page-num {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dashboard-table-pagination .page-num .page {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #5b5b98;
}
.dashboard-table-pagination .page-num .arrows {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-table-pagination .page-num .arrows button {
  cursor: pointer;
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  padding: 0;
}
.dashboard-table-pagination .page-num .arrows button.disabled svg path {
  fill-opacity: 0.12;
}
.main-content.adm-dashboard .charts-wrapper .calendar {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.main-content.adm-dashboard .timelines {
  margin: 10px 15px 0;
}
.main-content.adm-dashboard .timelines .timeline {
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 15px;
  border-left: 3px solid #f7faff;
  border-bottom: 1px solid #f7faff;
}

.main-content.adm-dashboard .timelines .timeline h3 {
  color: #260944;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
}
.main-content.adm-dashboard .timelines .timeline h3::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 8px;
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: linear-gradient(149deg, #99b8f3 14.61%, #177fcb 100%);
  box-shadow: 0px 2.9868600368499756px 13.440899848937988px 0px rgba(126, 172, 235, 0.25);
}
.main-content.adm-dashboard .timelines .timeline div.msg {
  display: flex;
  gap: 7px;
  align-items: baseline;
  justify-content: flex-start;
}
.main-content.adm-dashboard .timelines .timeline div p {
  color: #5b5b98;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.2px;
}
.main-content.adm-dashboard .timelines .timeline h6 {
  color: #a9a9c8;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main-content.adm-dashboard .client-statistics.list-card .loading-wrapper {
  margin: 10px 30px 10px;
  padding-bottom: 15px;
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .charts {
    width: 100%;
  }
  .admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .calendar {
    width: 100%;
  }
  .admin-dashboard .main-dashboard .main-content.adm-dashboard .charts-wrapper .charts .client-statistics {
    width: 100%;
  }
}

.organisation {
  padding: 10px;
  border: none;
  border-radius: 10px;
}

.gov {
  background-color: rgb(159, 223, 191);
  color: rgb(64, 191, 127);
}
.cor {
  background-color: rgba(0, 111, 238, 0.1);
  color: rgb(90 117 224 / 87%);
}
.not-contact-yet {
}
.contacted {
}
.unable-to-contact {
}
.not-interested {
}

.follow-up {
}

.view-btn {
  border: 1px solid rgba(198, 198, 198, 0.5);
  padding: 10px;
  border-radius: 50px;
  text-align: center;
}

.view-btn:hover {
  background-color: black;
  color: white;
}

.member-table,
.username-cell {
  display: flex;
  gap: 6px;
}

.AssessmentAnalyticsSectionButtons {
  background-color: red;
}
.jobrole-chip {
  border: 1.2px solid rgba(198, 198, 198, 0.5);
  font-weight: 500;
  padding: 4px 0;
  font-size: 12px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}
